window.$ = require('jquery/dist/jquery.min');
window.jQuery = require('jquery/dist/jquery.min'); // rwdImageMaps用

import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import('./jquery.rwdImageMaps.min');

import('../scss/main.scss');

// スライダー
const swiper = new Swiper('.mv_main-swiper', {
    speed: 1500,
    autoplay: {
        delay: 5000,
    },
    loop: true,
});
const mySwiper2 = new Swiper('.mv_top-swiper', {
    speed: 1500,
    autoplay: {
        delay: 5000,
    },
    loop: true,
});
const mySwiper3 = new Swiper('.mv_under-swiper', {
    speed: 1500,
    autoplay: {
        delay: 5000,
    },
    loop: true,
});

// tab
$(function () {
    $('.tt_tab_btn li').click(function () {
        var index = $('.tt_tab_btn li').index(this);
        $('.tt_tab_btn li').removeClass('active');
        $(this).addClass('active');
        $('.tt_tab_area ul').removeClass('show').eq(index).addClass('show');
    });
});

// ハンバーガー
$(function () {
    $('.nav_toggle').click(function () {
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            $('#global_navi').addClass('active');
        } else {
            $('#global_navi').removeClass('active');
        }
    });
});

// ヘッダー固定
$(function () {
    const headerHeight = 58; //ヘッダの高さ
    $('a[href^="#"]').click(function () {
        const href = $(this).attr("href");
        const target = $(href === "#" || href === "" ? 'html' : href);
        const position = target.offset().top - headerHeight; //ヘッダの高さ分位置をずらす
        $("html, body").animate({scrollTop: position}, 550, "swing");
        return false;
    });
    $('.map_item').click(function () {
        const href = $(this).attr("href");
        const target = $(href === "#" || href === "" ? 'html' : href);
        const position = target.offset().top - headerHeight; //ヘッダの高さ分位置をずらす
        $("html, body").animate({scrollTop: position}, 550, "swing");
        return false;
    });
});


// login-井上さん修正
$(function() {
  $('.header_member').click(function() {
    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {
        $('.login_form').fadeIn();
    } else {
        $('.login_form').fadeOut();
    }
  });

  $('.modal-close').click(function() {
    $('.header_member').removeClass('active');

    if ($('.header_member').hasClass('active')) {
      $('.login_form').fadeIn();
  } else {
      $('.login_form').fadeOut();
  }
  });

  $(document).click(function(event){
      var target = $(event.target);

      if(target.hasClass('login_form')) {
        $('.login_form').fadeOut();
      }
  });

});

//フォーム
$(function(){

  if($(".v-text-field__slot").children('.v-input').val() !== ''){
  $(".v-text-field__slot").addClass('primary--text');
  $(".v-text-field__slot").children('.v-label').addClass('v-label--active');
  }

	$(".header_member a").on("click", function() {
		$("#slidetoggle_menu").slideToggle();
		$("#slidetoggle_menu").toggleClass("active");
	});

	$(".v-text-field__slot").focusin(function(){
		$(this).addClass('primary--text');
		$(this).children('.v-label').addClass('v-label--active');
	});

	$(".v-text-field__slot").focusout(function(){
    if($(this).children('.v-input').val() === ''){
      $(this).removeClass('primary--text');
      $(this).children('.v-label').removeClass('v-label--active');
    }
	});
});

// top
$(document).ready(function () {
    $(".to_top").hide();
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.to_top').fadeIn();
            } else {
                $('.to_top').fadeOut();
            }
        });
    });
});

// map
jQuery(function ($) {
    $('img[usemap]').rwdImageMaps();
});
